<template>
  <b-form-group
    :label="label"
    :label-for="type + '.' + index + '.price'"
  >
    <b-input-group
      :prepend="currencyBase.code.toUpperCase()"
      class="base_currency"
    >
      <b-form-input
        :id="type + '.' + index + '.price'"
        v-model="priceBase"
        type="number"
        step="1"
        min="1"
        max="999999"
        :state="errors && errors[type + '.' + index + '.price'] ? false : null"
      />
    </b-input-group>
    <b-input-group :prepend="currencyOther.code.toUpperCase()">
      <b-form-input
        :id="type + '.' + index + '.price'"
        v-model="priceOther"
        type="number"
        step="1"
        min="0"
        max="999999"
        :state="errors && errors[type + '.' + index + '.price'] ? false : null"
      />
    </b-input-group>

    <b-form-invalid-feedback v-if="errors && errors[type + '.' + index + '.price']">
      {{ errors[type + '.' + index + '.price'][0] }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      default: 'uah',
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      internalPrice: this.value,
    }
  },
  computed: {
    otherCode() {
      return this.currency === this.userData.currencies.settings.currency ? this.userData.currencies.settings.admin_currency : this.userData.currencies.settings.currency
    },
    currencyBase() {
      return this.userData.currencies.list.find(item => item.code === this.currency) || { code: 'uah', value: 1 }
    },
    currencyOther() {
      return this.userData.currencies.list.find(item => item.code === this.otherCode) || { code: 'usd', value: 1 }
    },
    priceBase: {
      get() {
        return this.internalPrice
      },
      set(value) {
        this.internalPrice = Math.round(value) // Округлення до цілого
        this.$emit('input', this.internalPrice) // Оповіщення батьківського компоненту про зміну
      },
    },
    priceOther: {
      get() {
        return Math.round((this.internalPrice * this.currencyBase.value) / this.currencyOther.value) // Округлення до цілого
      },
      set(value) {
        this.internalPrice = Math.round((value / this.currencyBase.value) * this.currencyOther.value) // Округлення до цілого
        this.$emit('input', this.internalPrice) // Оповіщення батьківського компоненту про зміну
      },
    },
  },
  watch: {
    value(newValue) {
      this.internalPrice = newValue // Оновлення локальної копії v-model значення при зміні
    },
  },
}
</script>

<style scoped>
.base_currency .input-group-text {
  background: rgba(40, 199, 111, 0.5);
}
</style>
