<template>
  <div>
    <filters
      :status-filter.sync="statusFilter"
      :indexing-status-filter.sync="indexingStatusFilter"
      :stock-status-filter.sync="stockStatusFilter"
      :need-manage-filter.sync="needManageFilter"
      :categories-filter.sync="categoriesFilter"
      :brands-filter.sync="brandsFilter"
      :price-min.sync="priceMin"
      :price-max.sync="priceMax"
      :qnt-min.sync="qntMin"
      :qnt-max.sync="qntMax"
      :categories="categories"
      :statuses="statuses"
      @refetch="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.table.settings.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('admin.table.settings.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('admin.table.settings.search') + '...'"
              />
              <b-button
                v-if="$ability.can('store', 'products')"
                variant="primary"
                :to="{ name: 'products-store' }"
              >
                <span class="text-nowrap">{{ $t('general.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(avatar)="data">
          <EditProductData
            :field="'avatar'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <b-media no-body>
              <b-media-aside>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="$options.filters.mediaUrl(data.item, 'avatar', '150x150')"
                  height="90"
                  width="90"
                />
                <!--/ avatar -->
              </b-media-aside>
            </b-media>
          </EditProductData>
        </template>

        <template #cell(status)="data">
          <EditProductData
            :field="'status'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <span :style="`color: ${data.item.status === 'enabled' ? 'green' : 'red'};`">
              {{ $t(`general.statuses.${data.item.status}`) }}
            </span>
          </EditProductData>
        </template>

        <template #cell(title)="data">
          <EditProductData
            :field="'title'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            {{ data.item.title }}
          </EditProductData>
        </template>

        <template #cell(sku)="data">
          <EditProductData
            :field="'sku'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            {{ data.item.sku }}
          </EditProductData>
        </template>

        <template #cell(stock_status)="data">
          <EditProductData
            :field="'stock_status'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <span :style="`color: ${data.item.stock_status.color};`">{{ data.item.stock_status.title }}</span>
          </EditProductData>
        </template>

        <template #cell(position)="data">
          <EditProductData
            :field="'position'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            {{ data.item.position }}
          </EditProductData>
        </template>

        <template #cell(quantity)="data">
          <EditProductData
            :field="'stocks'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <div class="d-flex flex-column">
              <div
                v-for="line in data.item.stocks"
                :key="line.id"
              >
                <span :class="`btn btn-sm btn-${line.quantity <= 0 ? 'danger' : (line.quantity < 5 ? 'warning' : 'success')}`">
                  {{ line.quantity }}
                </span>
              </div>
              <div v-if="!data.item.stocks.length">
                не вказано
              </div>
            </div>
          </EditProductData>
        </template>

        <template #cell(price)="data">
          <EditProductData
            :field="'price'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <div class="d-flex flex-column">
              <div
                v-for="line in data.item.prices"
                :key="line.id"
              >
                <s
                  v-if="line.old_price && line.old_price !== line.price"
                  v-html="$options.filters.money(line.old_price, true, data.item.currency_code)"
                />
                <div v-html="$options.filters.money(line.price, true, data.item.currency_code)" />
              </div>
              <div
                v-for="line in data.item.discounts"
                :key="line.id"
              >
                <div v-html="$options.filters.money(line.price, true, data.item.currency_code)" />
              </div>
              <div v-if="!data.item.prices.length">
                не вказано
              </div>
            </div>
          </EditProductData>
        </template>

        <template #cell(categories)="data">
          <EditProductData
            :field="'categories'"
            :product="data.item"
            :languages="languages"
            :statuses="statuses"
            :currencies="currencies"
            :categories="categories"
            @refresh="refetchData"
          >
            <div class="d-flex flex-column">
              <div
                v-for="line in data.item.categories"
                :key="line.id"
              >
                {{ line.title }}
              </div>
              <div v-if="!data.item.categories.length">
                не вказано
              </div>
            </div>
          </EditProductData>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <a
              :href="`${apiUrl}/products/${data.item.slug}`"
              target="_blank"
              class="btn btn-info btn-sm"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle"
              />
            </a>
            <router-link
              v-if="$ability.can('update', 'products')"
              :to="{ name: 'products-update', params: { id: data.item.id }, query: { oldpage: currentPage, query: searchQuery, category_id: categoriesFilter, status: statusFilter, indexing_status: indexingStatusFilter, need_manage: needManageFilter } }"
              class="btn btn-primary btn-sm ml-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="align-middle"
              />
            </router-link>
            <span
              v-if="$ability.can('update', 'products')"
              class="btn btn-warning btn-sm ml-1"
              @click="copy(data.item.id)"
            >
              <feather-icon
                icon="CopyIcon"
                size="16"
                class="align-middle"
              />
            </span>
            <span
              v-if="$ability.can('destroy', 'products')"
              class="btn btn-danger btn-sm ml-1"
              @click="destroy(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle"
              />
            </span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('admin.table.settings.showing') }} {{ dataMeta.from }} {{ $t('admin.table.settings.to') }} {{ dataMeta.to }} {{ $t('admin.table.settings.of') }} {{ dataMeta.of }} {{ $t('admin.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BButton,
  BMedia,
  BMediaAside,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import EditProductData from '@/components/EditProductData.vue'
import Filters from './Filters.vue'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    Filters,
    EditProductData,
    BMedia,
    BMediaAside,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,

    vSelect,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      apiUrl: process.env.VUE_APP_APP_URL,
      tableColumns: [
        { key: 'avatar', label: this.$t('form.avatar.label'), sortable: false },
        { key: 'title', label: this.$t('admin.table.fields.title'), sortable: true },
        { key: 'sku', label: 'SKU', sortable: false },
        { key: 'categories', label: this.$t('admin.table.fields.category'), sortable: false },
        { key: 'price', label: this.$t('admin.table.fields.price'), sortable: false },
        { key: 'quantity', label: this.$t('admin.table.fields.quantity'), sortable: false },
        { key: 'stock_status', label: this.$t('form.stock_status.label'), sortable: false },
        { key: 'status', label: this.$t('admin.table.fields.status'), sortable: false },
        { key: 'position', label: this.$t('admin.table.fields.position'), sortable: true },
        { key: 'actions', label: this.$t('admin.table.fields.actions') },
      ],
      languages: [],
      statuses: [],
      currencies: [],
      categories: [],
    }
  },
  watch: {
    '$route.query.oldpage': {
      handler(page) {
        this.$nextTick(() => {
          if (this.$route.query.oldpage && parseInt(page, 10) >= 1) {
            this.currentPage = page
          }
        })
      },
      deep: true,
      immediate: true,
    },
    '$route.query.query': {
      handler(search) {
        if (this.$route.query.query) {
          this.searchQuery = search
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.query.category_id': {
      handler(value) {
        if (this.$route.query.category_id) {
          this.categoriesFilter = value
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.query.status': {
      handler(value) {
        if (this.$route.query.status) {
          this.statusFilter = value
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.query.need_manage': {
      handler(value) {
        if (this.$route.query.need_manage) {
          this.needManageFilter = value
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.query.indexing_status': {
      handler(value) {
        if (this.$route.query.indexing_status) {
          this.indexingStatusFilter = value
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })
    await this.$http.get('/api/admin/statuses')
      .then(response => {
        this.statuses = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
    await this.$http.get('/api/admin/fullcategories', { params: { type: 'product', status: 'enabled', return: 'transform' } })
      .then(response => {
        this.categories = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
    this.$http('/api/currencies')
      .then(response => {
        this.currencies = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/products/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    copy(id) {
      this.confirm(() => {
        this.$http.post(`/api/admin/products/${id}/copy`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: 'Бажаєте скопіювати товар?',
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'products'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      categoriesFilter,
      brandsFilter,
      statusFilter,
      stockStatusFilter,
      needManageFilter,
      indexingStatusFilter,
      priceMin,
      priceMax,
      qntMin,
      qntMax,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      categoriesFilter,
      brandsFilter,
      statusFilter,
      stockStatusFilter,
      needManageFilter,
      indexingStatusFilter,
      priceMin,
      priceMax,
      qntMin,
      qntMax,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
